/* External dependencies */
import React from 'react';
import { Link } from 'react-router-dom';
import get from 'lodash/get';

/* Internal dependencies */
import MediaObject, { Props as MediaObjectProps } from '../mediaObject/MediaObject';
import { formatNumber } from 'src/common/helpers/numbers';
import Icon, { Icons } from 'src/icon/Icon';

type OwnProps = {
  community: any;
  right?: React.ReactNode;
  enableLink?: boolean;
} & Partial<MediaObjectProps>;

type Props = OwnProps;

const Community: React.FunctionComponent<Props> = ({ community, right, enableLink = false, textSize, ...rest }) => {
  const communityId = get(community, 'id', '');
  const verified = get(community, 'verified', false);
  const members = get(community, 'members.items', []);
  const membersTotal = get(community, 'members.total', members.length);
  const attendees = get(community, 'attendees.items', []);
  const attendeesTotal = get(community, 'attendees.total', attendees.length);

  return (
    <MediaObject
      text={Boolean(enableLink) ? (
        <Link
          className="d-flex align-items-center"
          to={`/communities/${communityId.replace('community:', '')}`}
        >
          <h6 className="text-white d-flex flex-wrap m-0 p-0" style={{ fontSize: textSize }}>{get(community, 'name')}</h6>
          {Boolean(verified) && (
            <Icon
              name={Icons.verified}
              className="bm-Icon--primary ml-1 pb-1"
              size={16}
              solid
            />
          )}
        </Link>
      ) : (
        <div className="d-flex align-items-center">
          <span className="text-white m-0 p-0" style={{ fontSize: textSize }}>{get(community, 'name')}</span>
          {Boolean(verified) && (
            <Icon
              name={Icons.verified}
              className="bm-Icon--primary ml-1 pb-1"
              size={16}
              solid
            />
          )}
        </div>
      )}
      textSize={textSize}
      subtext={`${formatNumber(membersTotal)} ${membersTotal === 1 ? 'member' : 'members'}${Boolean(attendeesTotal) ? `, ${formatNumber(attendeesTotal)} ${attendeesTotal === 1 ? 'attendee' : 'attendees'}` : ''}`}
      imageType="rounded"
      image={get(community, 'images[0].url')}
      right={right}
      {...rest}
    />
  );
};

export default Community;
