/* External dependencies */
import Amplify from 'aws-amplify';

export enum ApiName {
  bmApp = 'bmApp',
}

const amplifyInit = () => {
  Amplify.configure({
    Auth: {
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
      region: 'us-west-2', // hard-code region for user pool
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false,
      // refreshHandlers: {
      //   facebook: refreshToken,
      // }
    },
    API: {
      endpoints: [
        {
          name: ApiName.bmApp,
          endpoint: `${process.env.REACT_APP_API_GATEWAY_URL}`,
          region: process.env.REACT_APP_REGION,
        },
      ],
    },
  });
};

export default amplifyInit;
