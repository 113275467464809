/* External dependencies */
import get from 'lodash/get';
import shuffle from 'lodash/shuffle';
import intersectionBy from 'lodash/intersectionBy';

/* Internal dependencies */
import { CurrentUserState } from '../ducks/currentUser';
import { cleanObject } from 'src/common/cleanObject';
import { Image, User } from 'src/types/User';

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const slugify = (name: string = '') => {
  const slug = name.split(' ').join('-');
  return window.encodeURIComponent(slug);
};

// TODO: Type out API response to ensure modeling works correctly
export const modelUser = (user: any = {}): User => cleanObject({
  ...user,
  name: capitalizeFirstLetter(user.name || ''),
  birthday: user.birthday && new Date(get(user, 'birthday')),
  images: get(user, 'images', []).filter(
    ({ url }: Image) => url.startsWith('https') || url.startsWith('http')
  ),
  answers: (user.answers || []).slice(0, 3),
  artists: get(user, 'artists.items', []),
  artistsTotal: get(user, 'artists.total', 0),
  sharedArtists: get(user, 'sharedArtists.items', []),
  sharedArtistsTotal: get(user, 'sharedArtists.total', 0),
  achievements: get(user, 'achievements.items', []),
  achievementsTotal: get(user, 'achievements.total', 0),
  achievementsOffset: get(user, 'achievements.offset'),
  achievementGroups: get(user, 'achievementGroups.items', []),
  achievementGroupsTotal: get(user, 'achievementGroups.total', 0),
  achievementGroupsOffset: get(user, 'achievementGroups.offset'),
  activities: user && user.activities && user.activities.items || [],
  communities: get(user, 'communities.items', []),
  communitiesTotal: get(user, 'communities.total', 0),
  communitiesOffset: get(user, 'communities.offset'),
  createdCommunities: get(user, 'createdCommunities.items', []),
  createdCommunitiesTotal: get(user, 'createdCommunities.total', 0),
  createdCommunitiesOffset: get(user, 'createdCommunities.offset'),
  events: get(user, 'events.items', []),
  eventsTotal: get(user, 'events.total', 0),
  eventsOffset: get(user, 'events.offset'),
  orders: get(user, 'orders.items', []),
  ordersTotal: get(user, 'orders.total', 0),
  ordersOffset: get(user, 'orders.offset'),
  pastOrders: get(user, 'pastOrders.items', []),
  pastOrdersTotal: get(user, 'pastOrders.total', 0),
  pastOrdersOffset: get(user, 'pastOrders.offset'),
  createdEvents: get(user, 'createdEvents.items', []),
  createdEventsTotal: get(user, 'createdEvents.total', 0),
  createdEventsOffset: get(user, 'createdEvents.offset'),
  pastEvents: get(user, 'pastEvents.items', []),
  pastEventsTotal: get(user, 'pastEvents.total', 0),
  pastEventsOffset: get(user, 'pastEvents.offset'),
  sharedEvents: get(user, 'sharedEvents.items', []),
  sharedEventsTotal: get(user, 'sharedEvents.total', 0),
  genres: get(user, 'genres.items', []),
  desires: get(user, 'desires.items', []),
  tracks: get(user, 'tracks.items', []),
  travels: get(user, 'travels.items', []), 
  stories: get(user, 'stories.items', []), // TODO: map modelUser here
  friends: get(user, 'friends.items', []).map(modelUser),
  friendsOffset: get(user, 'friends.offset'),
  friendsTotal: get(user, 'friends.total', 0),
  friendRequests: get(user, 'friendRequests.items', []).map((friendRequest: any) => ({
    ...friendRequest,
    user: modelUser(get(friendRequest, 'user', {})),
  })),
  friendRequestsOffset: get(user, 'friendRequests.offset'),
  friendRequestsTotal: user && user.friendRequests && user.friendRequests.total,
  friendStatus: get(user, 'friendStatus'),
  blocks: get(user, 'blocks.items', []).map(modelUser),
  blocksOffset: get(user, 'blocks.offset'),
  blocksTotal: get(user, 'blocks.total', 0),
  contacts: get(user, 'contacts.items', []),
  contactsTotal: user && user.contacts && user.contacts.total,
  tracksOffset: get(user, 'tracks.lastEvaluatedKey'),
  travelsOffset: get(user, 'travels.offset'),
  storiesOffset: get(user, 'stories.offset'),
  artistsOffset: get(user, 'artists.lastEvaluatedKey'),
  activitiesOffset: user && user.activities && user.activities.offset,
  activitiesTotal: user && user.activities && user.activities.total,
  // artistIds: new Set(get(user, 'artistIds', [])),
  // eventIds: new Set(get(user, 'eventIds', [])),
  // genreIds: new Set(get(user, 'genreIds', [])),
  // desireIds: new Set(get(user, 'desireIds', [])),
  // trackIds: new Set(get(user, 'trackIds', [])),
  notifications: get(user, 'notifications.items', []).map((notification: any) => ({
    ...notification,
    users: {
      ...notification.users,
      items: (notification.users.items || []).map(modelUser),
    },
  })),
  notificationsOffset: get(user, 'notifications.lastEvaluatedKey'),
  notificationsUnseenTotal: get(user, 'notifications.unseenTotal'),
  savedTracks: get(user, 'savedTracks.items', []),
});

const getGenreInsight = (user: User, currentUser: User) => {
  if (!user || !currentUser || (currentUser.id === user.id)) return null;

  const top10GenresForUser = (user.genres || []).slice(0, 10);
  const top10GenresForCurrentUser = (currentUser.genres || []).slice(0, 10);
  const top10SharedGenres = shuffle(intersectionBy(top10GenresForUser, top10GenresForCurrentUser, 'id'));
  const recommendedTop10SharedGenre = top10SharedGenres[0];

  return recommendedTop10SharedGenre;
};

const getArtistInsight = (user: User, currentUser: User) => {
  if (!user || !currentUser || (currentUser.id === user.id)) return null;

  const top10ArtistsForUser = (user.artists || []).slice(0, 10);
  const top10ArtistsForCurrentUser = (currentUser.artists || []).slice(0, 10);
  const top10SharedArtists = shuffle(intersectionBy(top10ArtistsForUser, top10ArtistsForCurrentUser, 'id'));
  const recommendedTop10SharedArtist = top10SharedArtists[0];

  return recommendedTop10SharedArtist;
};

const getEventInsight = (user: User, currentUser: User) => {
  if (!user || !currentUser || (currentUser.id === user.id)) return null;

  const top10EventsForUser = (user.events || []).slice(0, 10);
  const top10EventsForCurrentUser = (currentUser.events || []).slice(0, 10);
  const top10SharedEvents = shuffle(intersectionBy(top10EventsForUser, top10EventsForCurrentUser, 'id'));
  const recommendedTop10SharedEvent = top10SharedEvents[0];

  return recommendedTop10SharedEvent;
};

export const getUserInsights = (user: User, currentUser: User) => {
  const insights = [];
  const artistInsight = getArtistInsight(user, currentUser);
  const eventInsight = getEventInsight(user, currentUser);
  const genreInsight = getGenreInsight(user, currentUser);

  if (eventInsight) {
    insights.push(eventInsight);
  }

  if (artistInsight) {
    insights.push(artistInsight);
  }

  if (genreInsight) {
    insights.push(genreInsight);
  }

  return insights;
};

export const getInsightSubtext = (insight: any) => {
  const type = get(insight, 'type');
  const name = get(insight, 'name');

  if (type === 'artist') {
    return `You both love ${name}`;
  } else if (type === 'event') {
    return `You both joined ${name}`;
  } else {
    return `You both listen to ${capitalizeFirstLetter(name).split(' ').join('-')}`;
  }
};

export const getUserSubtext = (user: User, currentUser: User, options: { showInsight?: boolean } = { showInsight: false }): string => {
  const { showInsight = false } = options;
  const userJobs = get(user, 'work.jobs', []);
  const userActiveJobIndex = get(user, 'work.activeJob', 0);
  const userActiveJob = userJobs[userActiveJobIndex];
  const userInstitutions = get(user, 'education.institutions', []);
  const userActiveInstitutionIndex = get(user, 'education.activeInstitution', 0);
  const userActiveInstitution = userInstitutions[userActiveInstitutionIndex];
  const insights = getUserInsights(user, currentUser);

  if (showInsight) {
    if (insights && insights.length) {
      const insight = insights[0];
      return getInsightSubtext(insight);
    }
  } else {
    if (Boolean(userActiveJob) && Boolean(userActiveJob.title)) {
      return `${userActiveJob.title.trim()}`
      // return `${userActiveJob.title.trim()}${userActiveJob.company ? ` at ${userActiveJob.company}` : ''}`;
    } else if (Boolean(userActiveInstitution) && Boolean(userActiveInstitution.name)) {
      return `${userActiveInstitution.name.trim()}`
      // return `${userActiveInstitution.name.trim()}${
      //   userActiveInstitution.graduationYear ? `, ${userActiveInstitution.graduationYear}` : ''
      // }`;
    }
  }

  return '';
};
