/* External depencies */
import React, { StyleHTMLAttributes, useState } from 'react';
import get from 'lodash/get';

/* Internal depencies */
import User from 'src/types/User';
import { randomItem } from 'src/common/helpers/random';

interface UserAvatarProps {
  user: User;
  size?: number;
  backgroundColor?: string;
  style?: any;
}

// Function to generate a random pleasant color
const getRandomColor = () => {
  const colors = ['#FFB300', '#6ACCBC', '#F3D24D', '#6BAED6', '#C594C5', '#E55D4A', '#FC814A'];
  return randomItem(colors);
};

const UserDefaultAvatar: React.FC<UserAvatarProps> = ({ user, size = 50, backgroundColor: initialBackgroundColor = getRandomColor(), style = {} }) => {
  const [backgroundColor] = useState(initialBackgroundColor);
  const firstName = get(user, 'firstName', get(user, 'name', ''));
  const lastName = get(user, 'lastName', '');
  const initials = `${get(firstName, '[0]', '')}${get(lastName, '[0]', '')}`.toUpperCase();

  const styles = {
    backgroundColor,
    color: '#FFFFFF',
    borderRadius: '50%',
    width: `${size}px`,
    height: `${size}px`,
    fontSize: `${size / 2.5}px`,
    fontWeight: 'bold',
    userSelect: 'none' as const,
    ...style,
  };

  return (
    <div className="d-flex justify-content-center align-items-center" style={styles}>
      {initials}
    </div>
  );
};

export default UserDefaultAvatar;
