/* External dependencies */
import { API } from 'aws-amplify';
import get from 'lodash/get';

/* Internal dependencies */
import { ApiName } from '../aws-exports';
import { cleanObject } from 'src/common/cleanObject';
import { PaginationOptions } from 'src/types/Pagination';
import { Image } from 'src/types/User';
import { modelUser } from 'src/store/helpers/users';
import Event from 'src/types/Event';

export const listBeatmatchEvents = async () => await API.get(ApiName.bmApp, `/events/listBeatmatchEvents`, {});

export const getEvent = async (eventId: string, options?: { cache: boolean }) =>
  await API.get(ApiName.bmApp, `/events/${eventId}`, {
    queryStringParameters: cleanObject({
      cache: options && options.cache,
    }),
  });

export const addEventForUser = async (userId: string, event: any) =>
  await API.post(ApiName.bmApp, `/users/${userId}/events`, {
    body: event,
  });

export const removeEventForUser = async (eventId: string, userId: string) =>
  await API.del(ApiName.bmApp, `/users/${userId}/events/${eventId}`, {});

export const listEventsForUser = async (userId: string, { limit, offset }: PaginationOptions) =>
  await API.get(ApiName.bmApp, `/users/${userId}/events`, {
    queryStringParameters: cleanObject({
      limit,
      offset: offset && JSON.stringify(offset),
    }),
  });

export const listCreatedEventsForUser = async (userId: string, { limit, offset }: PaginationOptions) =>
  await API.get(ApiName.bmApp, `/users/${userId}/createdEvents`, {
    queryStringParameters: cleanObject({
      limit,
      offset: offset && JSON.stringify(offset),
    }),
  });

export const listEvents = async ({
  startDate,
  endDate,
  radius,
  longitude,
  latitude,
  sort,
  offset,
  limit,
}: {
  startDate: Date,
  endDate: Date,
  radius?: number;
  longitude?: number;
  latitude?: number;
  sort?: string;
} & PaginationOptions) =>
  await API.get(ApiName.bmApp, `/events`, {
    queryStringParameters: cleanObject({
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      radius,
      longitude,
      latitude,
      sort,
      offset,
      limit,
    }),
  });

export const listUsersForEvent = async (
  eventId: string,
  { limit, offset }: PaginationOptions
) => {
  const response = await API.get(ApiName.bmApp, `/events/${eventId}/users`, {
    queryStringParameters: {
      limit,
      offset,
    },
  });

  return {
    ...response,
    items: response.items && response.items.map(modelUser),
  };
};

export const listOrdersForEvent = async (
  eventId: string,
  options: PaginationOptions & { name?: string; }
) => {
  const offset = get(options, 'offset');
  const response = await API.get(ApiName.bmApp, `/events/${eventId}/orders`, {
    queryStringParameters: cleanObject({
      ...options,
      offset: Boolean(offset) ? JSON.stringify(offset) : undefined,
    }),
  });

  return response;
};

export const addEvent = async (event: any) =>
  await API.post(ApiName.bmApp, `/events`, {
    body: event,
  });

export const updateEvent = async (event: any) =>
  await API.put(ApiName.bmApp, `/events/${get(event, 'id')}`, {
    body: event,
  });

export const updateEventPreferences = async (eventId: string, preferences: Event['preferences']) =>
  await API.put(ApiName.bmApp, `/events/${eventId}/preferences`, {
    body: preferences,
  });

export const addImageForEvent = async (eventId: string, image: Omit<Image, 'url'>) =>
  await API.post(ApiName.bmApp, `/events/${eventId}/images`, {
    body: image,
  });

export const updateImageForEvent = async (eventId: string, image: Omit<Image, 'url'>, imageIndex: number) =>
  await API.put(ApiName.bmApp, `/events/${eventId}/images/${imageIndex}`, {
    body: image,
  });

export const addCreatorForEvent = async (eventId: string, { id: creatorId }: any) =>
  await API.post(ApiName.bmApp, `/events/${eventId}/creators`, {
    body: { creatorId },
  });

export const removeCreatorForEvent = async (eventId: string, { id: creatorId }: any) =>
  await API.del(ApiName.bmApp, `/events/${eventId}/creators/${creatorId}`, {});

export const respondToCreatorInvitation = async (eventId: string, { id: creatorId, status }: any) =>
  await API.put(ApiName.bmApp, `/events/${eventId}/creators/${creatorId}`, {
    body: { status },
  });

export const addTicketForEvent = async (eventId: string, ticket: any) =>
  await API.post(ApiName.bmApp, `/events/${eventId}/tickets`, {
    body: ticket,
  });

export const removeTicketForEvent = async (eventId: string, { id: ticketId }: any) =>
  await API.del(ApiName.bmApp, `/events/${eventId}/tickets/${ticketId}`, {});

export const updateTicketForEvent = async (eventId: string, ticket: any) =>
  await API.put(ApiName.bmApp, `/events/${eventId}/tickets/${get(ticket, 'id')}`, {
    body: ticket,
  });

export const addArtistForEvent = async (eventId: string, { provider, providerId }: any) =>
  await API.post(ApiName.bmApp, `/events/${eventId}/artists`, {
    body: { provider, providerId },
  });

export const removeArtistForEvent = async (eventId: string, { id: artistId }: any) =>
  await API.del(ApiName.bmApp, `/events/${eventId}/artists/${artistId}`, {});

export const addTrackForEvent = async (eventId: string, track: any) =>
  await API.post(ApiName.bmApp, `/events/${eventId}/tracks`, {
    body: track,
  });

export const removeTrackForEvent = async (eventId: string, { id: trackId }: any) =>
  await API.del(ApiName.bmApp, `/events/${eventId}/tracks/${trackId}`, {});

export const addHashtagForEvent = async (eventId: string, hashtag: any) =>
  await API.post(ApiName.bmApp, `/events/${eventId}/hashtags`, {
    body: hashtag,
  });

export const removeHashtagForEvent = async (eventId: string, { id: hashtagId }: any) =>
  await API.del(ApiName.bmApp, `/events/${eventId}/hashtags/${hashtagId}`, {});

export const addGenreForEvent = async (eventId: string, genre: any) =>
  await API.post(ApiName.bmApp, `/events/${eventId}/genres`, {
    body: genre,
  });

export const removeGenreForEvent = async (eventId: string, { id: genreId }: any) =>
  await API.del(ApiName.bmApp, `/events/${eventId}/genres/${genreId}`, {});