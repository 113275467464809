/* External dependencies */
import React from 'react';

/* Internal dependencies */
import './MediaObject.scss';

type OwnProps = {
  text: React.ReactNode;
  textSize?: number | string;
  textColor?: 'light' | 'dark';
  subtext?: React.ReactNode;
  subtextSize?: number | string;
  image?: React.ReactNode;
  imageType?: 'rounded' | 'circle' | 'square';
  imageSize?: number;
  right?: React.ReactNode;
  wrap?: boolean;
  className?: string;
  style?: any;
};

export type Props = OwnProps;

export const DEFAULT_IMAGE_SIZE = 75;

const MediaObject: React.FunctionComponent<Props> = ({
  text,
  subtext,
  textColor,
  textSize,
  subtextSize,
  image,
  imageType = 'rounded',
  imageSize = DEFAULT_IMAGE_SIZE,
  right,
  wrap = true,
  className = '',
  style,
}) => {
  const imageStyles: any = {
    height: imageSize,
    width: imageSize,
    overflow: 'hidden',
    objectFit: 'cover',
    borderRadius: imageType === 'rounded' ? '0.5rem' : imageType === 'square' ? 0 : '50%',
  };

  return (
    <div className={`bm-MediaObject${wrap ? ' flex-wrap' : ''} ${className}`} style={style}>
      <div className={`bm-MediaObject__container d-flex align-items-center${wrap ? ' bm-MediaObject__container--wrap' : ''}`} style={{ maxWidth: '100%' }}>
        <div className="bm-MediaObject__imageContainer">
          {typeof image === 'string' ? (
            <img alt={typeof text === 'string' ? text : typeof subtext === 'string' ? subtext : ''} src={image} style={imageStyles} />
          ) : Boolean(image) ? (
            <>{image}</>
          ) : <div style={{ height: imageSize }} />}
        </div>
        <div className="bm-MediaObject__textContainer d-flex flex-column justify-content-center" style={{ width: '100%' }}>
          {typeof text === 'string' ? (
            <h6
              className={`bm-MediaObject_text d-flex flex-wrap ${Boolean(subtext) ? 'mb-1' : ''} m-0 p-0 ${textColor === 'light' ? 'text-light' : 'text-dark'}`}
              style={{ fontSize: textSize }}
            >
              {text}
            </h6>
          ) : text}
          {Boolean(subtext) && (
            <>
              {typeof subtext === 'string' ? (
                <h6
                  className="bm-MediaObject_text bm-MediaObject__subtext d-flex flex-wrap m-0 p-0"
                  style={{ fontSize: subtextSize }}
                >
                  {subtext}
                </h6>
              ) : (
                subtext
              )}
            </>
          )}
        </div>
      </div>
      {Boolean(right) && (
        <>{right}</>
      )}
    </div>
  );
};

export default MediaObject;
