/* External dependencies */
import { API } from 'aws-amplify';
import { logEvent } from '@firebase/analytics';

/* Internal dependencies */
import { ApiName } from '../aws-exports';
import { Member } from 'src/types/Member';
import { PaginationOptions } from 'src/types/Pagination';
import { Waitlist } from 'src/types/Waitlist';
import { analytics } from 'src/firebase';

const LAUNCH_WAITLIST_ID = 'waitlist:8fb630d8-e230-48a9-b9b7-4df460fa9067';

export const listWaitlists = async (limit: number = 30) =>
  await API.get(ApiName.bmApp, `/waitlists`, {
    queryStringParameters: { limit },
  });

export const listMembersForWaitlist = async (waitlistId: string, { limit = 10 }: PaginationOptions) =>
  await API.get(ApiName.bmApp, `/waitlists/${waitlistId}/members`, {
    queryStringParameters: { limit },
  });

export const addMemberForWaitlist = async (waitlistId: string, member: Partial<Member>) =>
  await API.post(ApiName.bmApp, `/waitlists/${waitlistId}/members`, {
    body: member,
  });

export const addMemberForLaunchWaitlist = async (member: Partial<Member>) => {
  const memberConfirmed = await addMemberForWaitlist(LAUNCH_WAITLIST_ID, member);
  logEvent(analytics, 'waitlist_join', memberConfirmed);
  return memberConfirmed;
};

export const updateMemberForWaitlist = async (waitlistId: string, member: Partial<Member>) =>
  await API.put(ApiName.bmApp, `/waitlists/${waitlistId}/members/${member.id}`, { body: member });

export const updateMemberForLaunchWaitlist = async (member: Partial<Member>) => {
  const updatedMember = await updateMemberForWaitlist(LAUNCH_WAITLIST_ID, member);
  logEvent(analytics, 'waitlist_updated', updatedMember);
  return updatedMember;
};

export const createWaitlist = async (waitlist: Waitlist) =>
  await API.post(ApiName.bmApp, `/waitlists`, { body: waitlist });

export const getWaitlist = async (waitlistId: string) => await API.get(ApiName.bmApp, `/waitlists/${waitlistId}`, {});

export const getLaunchWaitlist = async () => await API.get(ApiName.bmApp, `/waitlists/${LAUNCH_WAITLIST_ID}`, {});
