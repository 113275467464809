/* Export dependencies */
import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Marquee from 'react-fast-marquee';

/* Internal dependencies */
import ForFansDiscoverEvents from '../assets/images/for-fans-discover-events.png';
import ForFansJoinCommunities from '../assets/images/for-fans-join-communities.png';
import ForFansFriendsPlans from '../assets/images/for-fans-friends-plans.png';
import ForFansMakeMomentsHappen from '../assets/images/for-fans-make-moments-happen.png';
import MusicLoverRadar2 from '../assets/images/music-lover-radar-2.png';
import MusicLoverRadar3 from '../assets/images/music-lover-radar-3.png';
import MusicLoverRadar4 from '../assets/images/music-lover-radar-4.png';
import MusicLoverRadar5 from '../assets/images/music-lover-radar-5.png';
import MusicLoverRadar6 from '../assets/images/music-lover-radar-6.png';
import MusicLoverRadar7 from '../assets/images/music-lover-radar-7.png';
import CardiB from '../assets/images/highlights-cardi-b.jpeg';
import KAYTRANADA from '../assets/images/highlights-kaytranada.webp';
import KAYTRANADA2 from '../assets/images/highlights-kaytranada-2.jpeg';
import DojaCat from '../assets/images/highlights-doja-cat.jpeg';
import Coachella from '../assets/images/highlights-coachella.jpeg';
import Blxst from '../assets/images/highlights-blxst.jpeg';
import BlxstChosen from '../assets/images/highlights-blxst-chosen.jpeg';
import Deadmau5 from '../assets/images/highlights-deadmau5.jpeg';
import BurnaBoy from '../assets/images/highlights-burna-boy.jpeg';
import ConcertCrowd from '../assets/images/highlights-concert-crowd.jpeg';
import PartyCrowd from '../assets/images/highlights-party-crowd.jpeg';
import PartyCrowd2 from '../assets/images/highlights-party-crowd-2.jpeg';
import PartyCrowd3 from '../assets/images/highlights-party-crowd-3.jpeg';
import EventsParty from '../assets/images/events-party.gif';
import JoinTheParty from '../assets/images/join-the-party.png';
import WaitlistMember1 from '../assets/images/waitlist-member-1.jpeg';
import WaitlistMember2 from '../assets/images/waitlist-member-2.jpeg';
import WaitlistMember5 from '../assets/images/waitlist-member-5.jpeg';
import WaitlistMember8 from '../assets/images/waitlist-member-8.jpeg';
import '../App.scss';
import './ForFans.scss';
import '../events/Events.scss';
import Slideshow from 'src/slideshow/Slideshow';
import SubscribeButton from 'src/subscribe/SubscribeButton';
import { ReactComponent as WarnerMusic } from '../assets/images/warner-music-group.svg';
import { ReactComponent as QualityControlMusic } from '../assets/images/quality-control-music.svg';
import { ReactComponent as SonyMusic } from '../assets/images/sony-music.svg';
import { ReactComponent as HYBEMusic } from '../assets/images/hybe-music.svg';
import { ReactComponent as RightHandMusic } from '../assets/images/right-hand-music-group.svg';
import { ReactComponent as ConcordMusic } from '../assets/images/concord-music.svg';
import { ReactComponent as TechstarsMusic } from '../assets/images/techstars-music.svg';
import { ReactComponent as PressGoogle } from '../assets/images/press-google.svg';
import { ReactComponent as PressGeekWire } from '../assets/images/press-geekwire.svg';
import { ReactComponent as PressNasdaq } from '../assets/images/press-nasdaq.svg';
import Icon, { Icons } from 'src/icon/Icon';
import { listEvents } from 'src/api/events';
import moment from 'moment';
import FeatureSection from './FeatureSection';
import ScrollToTop from 'src/scrollToTop/ScrollToTop';

const FEATURE_SECTIONS = [
  {
    heading: 'Discover the best nights out',
    subheading: 'Find parties, concerts, festivals, and more happening near you based on your music library.',
    image: ForFansDiscoverEvents,
    actionButton: (
      <Link
        className="btn btn-secondary bm-ForFans__primaryCTA pt-2 pb-2 pl-4 pr-4 mb-5"
        to="/explore"
        style={{ borderRadius: 100 }}
      >
        <h5 className="pt-1 text-dark">Explore events</h5>
      </Link>
    ),
  },
  {
    heading: 'Find your tribe',
    subheading: 'Join local communities to support your favorite organizers, get updates, and meet your people.',
    image: ForFansJoinCommunities,
    actionButton: (
      <Link
        className="btn btn-secondary bm-ForFans__primaryCTA pt-2 pb-2 pl-4 pr-4 mb-5"
        to="/explore"
        style={{ borderRadius: 100 }}
      >
        <h5 className="pt-1 text-dark">Discover communities</h5>
      </Link>
    ),
  },
  {
    heading: 'Make events social again',
    subheading: 'See what your friends and locals are doing before missing out.',
    image: ForFansFriendsPlans,
    actionButton: (
      <SubscribeButton variant="secondary" className="btn btn-secondary bm-ForFans__primaryCTA pt-2 pb-2 pl-4 pr-4 mb-5">
        <h5 className="pt-1 text-dark">Get started</h5>
      </SubscribeButton>
    ),
  },
  {
    heading: 'Plan your next great memory',
    subheading: 'Create and share event plans with friends or find locals to join you.',
    image: ForFansMakeMomentsHappen,
    actionButton: (
      <SubscribeButton variant="secondary" className="btn btn-secondary bm-ForFans__primaryCTA pt-2 pb-2 pl-4 pr-4 mb-5">
        <h5 className="pt-1 text-dark">Get started</h5>
      </SubscribeButton>
    ),
  },
  {
    heading: 'Meet your people',
    subheading: 'Connect with like-minded people in real life.',
    image: MusicLoverRadar3,
    actionButton: (
      <SubscribeButton variant="secondary" className="btn btn-secondary bm-ForFans__primaryCTA pt-2 pb-2 pl-4 pr-4 mb-5">
        <h5 className="pt-1 text-dark">Get started</h5>
      </SubscribeButton>
    ),
  },
];

const highlights = [
  {
    image: WaitlistMember1,
  },
  {
    image: WaitlistMember2,
    action: (
      <div style={{ position: 'absolute', top: -10, right: -10 }}>
        <div className="d-flex justify-content-center align-items-center bg-primary" style={{ height: 50, width: 50, borderRadius: 25, borderWidth: 5, borderColor: '#060710', borderStyle: 'solid' }}>
          <Icon name={Icons.heart} className="bm-Icon--white" size={26} />
        </div>
      </div>
    ),
  },
  {
    image: CardiB,
    badge: (
      <div style={{ position: 'absolute', bottom: 20, right: -10 }}>
        <div className="pl-3 pr-3 pt-1 pb-1" style={{ backgroundColor: '#FFF', borderRadius: 50 }}>Hip-Hop</div>
      </div>
    ),
  },
  {
    image: PartyCrowd2,
  },
  {
    image: ConcertCrowd,
  },
  {
    image: Coachella,
    action: (
      <div style={{ position: 'absolute', top: -10, right: -10 }}>
        <div className="d-flex justify-content-center align-items-center bg-white" style={{ height: 50, width: 50, borderRadius: 25, borderWidth: 5, borderColor: '#060710', borderStyle: 'solid' }}>
          <Icon name={Icons.checkCircle} className="bm-Icon--primary" solid size={50} />
        </div>
      </div>
    ),
    badge: (
      <div style={{ position: 'absolute', bottom: 20, left: -10 }}>
        <div className="d-flex align-items-center pl-1 pr-3 pt-1 pb-1" style={{ backgroundColor: '#FFF', borderRadius: 50, borderTopLeftRadius: 10, borderBottomLeftRadius: 10 }}>
          <Image
            className="mr-2"
            src={Coachella}
            height={25}
            width={25}
            style={{ borderRadius: 3, objectFit: 'cover' }}
          />
          <span>Festival</span>
        </div>
      </div>
    ),
  },
  {
    image: Deadmau5,
    badge: (
      <div style={{ position: 'absolute', bottom: 20, right: -10 }}>
        <div className="pl-3 pr-3 pt-1 pb-1" style={{ backgroundColor: '#FFF', borderRadius: 50 }}>EDM</div>
      </div>
    ),
  },
  {
    image: WaitlistMember5,
  },
  {
    image: WaitlistMember8,
  },
  {
    image: Blxst,
    action: (
      <div style={{ position: 'absolute', top: -10, right: -10 }}>
        <div className="d-flex justify-content-center align-items-center bg-white" style={{ height: 50, width: 50, borderRadius: 25, borderWidth: 5, borderColor: '#060710', borderStyle: 'solid' }}>
          <Icon name={Icons.bookmark} size={26} />
        </div>
      </div>
    ),
    badge: (
      <div style={{ position: 'absolute', bottom: 20, right: -20 }}>
        <div className="d-flex align-items-center pl-2 pr-3 pt-1 pb-1" style={{ backgroundColor: '#FFF', borderRadius: 5 }}>
          <Image
            className="mr-3"
            src={BlxstChosen}
            height={45}
            width={45}
            style={{ borderRadius: 5, objectFit: 'cover' }}
          />
          <div className="d-flex flex-column justify-content-center text-left" style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
            <h6 className="mb-1">Chosen</h6>
            <h6>Blxst, Ty Dolla $ign</h6>
          </div>
        </div>
      </div>
    ),
  },
  {
    image: PartyCrowd,
  },
  {
    image: KAYTRANADA,
    action: (
      <div style={{ position: 'absolute', top: -10, right: -10 }}>
        <div className="d-flex justify-content-center align-items-center bg-primary" style={{ height: 50, width: 50, borderRadius: 25, borderWidth: 5, borderColor: '#060710', borderStyle: 'solid' }}>
          <Icon name={Icons.send} className="bm-Icon--white" size={24} />
        </div>
      </div>
    ),
    badge: (
      <div style={{ position: 'absolute', bottom: 10, left: -10 }}>
        <div className="d-flex align-items-center pl-2 pr-3 pt-1 pb-1" style={{ backgroundColor: '#FFF', borderRadius: 50 }}>
          <Image
            className="mr-2"
            src={KAYTRANADA2}
            height={30}
            width={30}
            style={{ borderRadius: 15, objectFit: 'cover' }}
          />
          <span>KAYTRANADA</span>
        </div>
      </div>
    ),
  },
  {
    image: BurnaBoy,
    badge: (
      <div style={{ position: 'absolute', bottom: 20, right: -10 }}>
        <div className="pl-3 pr-3 pt-1 pb-1" style={{ backgroundColor: '#FFF', borderRadius: 50 }}>Afrobeats</div>
      </div>
    ),
  },
  {
    image: DojaCat,
    action: (
      <div style={{ position: 'absolute', top: -10, right: -10 }}>
        <div className="d-flex justify-content-center align-items-center bg-primary" style={{ height: 50, width: 50, borderRadius: 25, borderWidth: 5, borderColor: '#060710', borderStyle: 'solid' }}>
          <Icon name={Icons.heart} className="bm-Icon--white" size={26} />
        </div>
      </div>
    ),
    badge: (
      <div style={{ position: 'absolute', top: 20, left: 10 }}>
        <div className="d-flex align-items-center pl-2 pr-3 pt-1 pb-1" style={{ backgroundColor: '#FFF', borderRadius: 50 }}>
          <Image
            className="mr-2"
            src={DojaCat}
            height={30}
            width={30}
            style={{ borderRadius: 15, objectFit: 'cover' }}
          />
          <span>Doja Cat</span>
        </div>
      </div>
    ),
  },
  {
    image: PartyCrowd3,
    action: (
      <div style={{ position: 'absolute', top: -10, right: -10 }}>
        <div className="d-flex justify-content-center align-items-center bg-white" style={{ height: 50, width: 50, borderRadius: 25, borderWidth: 5, borderColor: '#060710', borderStyle: 'solid' }}>
          <Icon name={Icons.plus} size={28} />
        </div>
      </div>
    ),
    badge: (
      <div style={{ position: 'absolute', bottom: 20, right: -10 }}>
        <div className="pl-3 pr-3 pt-1 pb-1" style={{ backgroundColor: '#FFF', borderRadius: 50 }}>Pop-Up Party</div>
      </div>
    ),
  },
];

const ForFans: React.FC = () => {
  const [featuredEvents, setFeaturedEvents] = useState([]);

  useEffect(() => {
    const load = async () => {
      const { items: featuredEvents = [] } = await listEvents({
        offset: 0,
        limit: 4,
        startDate: new Date(),
        endDate: moment().add(6, 'months').toDate(),
        sort: 'popularity',
      });
  
      setFeaturedEvents(featuredEvents);
    };

    // load();
  }, []);

  return (
    <>
      <section className="bm-ForFans_section1" style={{ overflow: 'hidden' }}>
        <div data-aos="fade-in" className="container d-flex flex-column align-items-center">
          {/* <h1 className="bm-ForFans_heading pt-3 mb-4 text-white text-bold">Play music. Find <b id="headerWords"></b></h1> */}
          <h1 className="bm-ForFans_heading pt-3 mb-4 text-white text-bold">Do more together</h1>
          <h4 className="bm-ForFans_subheading mb-4 text-white">
            <h4>Create events, discover experiences, and build community <u>in real life</u></h4>
          </h4>
          <SubscribeButton variant="primary" className="bm-ForFans__primaryCTA mb-5">
            <h5 className="pt-1">Get started</h5>
          </SubscribeButton>
        </div>
        <div style={{ width: '100%' }}>
          <Marquee
            direction="left"
            speed={5}
            pauseOnHover={true}
            gradientColor={[0,0,0]}
            gradientWidth={50}
            style={{ paddingTop: 7.5, marginTop: -7.5, overflowY: 'hidden' }}
          >
            <div className="d-flex mb-4" data-aos="fade-in" style={{ marginLeft: -64, width: '100%' }}>
              {highlights.slice(0,8).map(({ image, action, badge }) => (
                <div className="bm-ForFans_highlight mr-4">
                  <Image
                    src={image}
                    height="100%"
                    width="100%"
                    style={{ objectFit: 'cover', borderRadius: 30 }}
                  />
                  {Boolean(badge) && badge}
                  {Boolean(action) && action}
                </div>
              ))}
            </div>
          </Marquee>
          <Marquee
            direction="right"
            speed={5}
            pauseOnHover={true}
            gradientColor={[0,0,0]}
            gradientWidth={50}
            style={{ paddingTop: 7.5, marginTop: -7.5, overflowY: 'hidden' }}
          >
            <div className="d-flex" data-aos="fade-in" style={{ width: '100%' }}>
              {highlights.slice(8,16).map(({ image, action, badge }) => (
                <div className="bm-ForFans_highlight mr-4">
                  <Image
                    src={image}
                    height="100%"
                    width="100%"
                    style={{ objectFit: 'cover', borderRadius: 30 }}
                  />
                  {Boolean(badge) && badge}
                  {Boolean(action) && action}
                </div>
              ))}
            </div>
          </Marquee>
        </div>
      </section>
      <section className="bm-ForFans_section2 container-fluid pt-5 pb-3">
        <h1 className="text-white" data-aos="fade-up">You may have heard</h1>
        <h1 className="bm-Text--strokeText mb-5" data-aos="fade-up">about us</h1>
        <div className="d-flex justify-content-between flex-wrap container" data-aos="fade-up" style={{ width: '100%' }}>
          <div
            className="bm-ForFans_pressContainer d-flex justify-content-center align-items-center"
            style={{ backgroundColor: '#211735' }}
          >
            <PressGoogle style={{ maxWidth: '35%' }} />
          </div>
          <div
            className="bm-ForFans_pressContainer d-flex justify-content-center align-items-center"
            style={{ backgroundColor: '#211735' }}
          >
            <PressNasdaq style={{ maxWidth: '40%' }} />
          </div>
          <div
            className="bm-ForFans_pressContainer d-flex justify-content-center align-items-center"
            style={{ backgroundColor: '#211735' }}
          >
            <PressGeekWire />
          </div>
        </div>
      </section>
      {/* <section className="bm-ForFans_section4 pt-3 pb-3">
        <div className="container" style={{ height: '100%', width: '100%' }}>
          <div style={{ width: '100%' }}>
            <div
              data-aos="fade-up"
              className={`container d-flex justify-content-between align-items-center flex-wrap`}
              style={{
                display: 'block',
                width: '100%',
              }}
            >
              <div className="bm-ForFans__bannerImage bm-ForFans__bannerImage--large">
                <Slideshow
                  id="musicLoverRadarCarousel"
                  interval={1500}
                  showPagination={false}
                  showControls={false}
                  data={[
                    MusicLoverRadar2,
                    MusicLoverRadar3,
                    MusicLoverRadar4,
                    MusicLoverRadar5,
                    MusicLoverRadar6,
                    MusicLoverRadar7,
                  ]}
                  dotColor="#9966ff"
                  renderSlide={(image, i) => (
                    <Image src={image} width="100%" fluid />
                  )}
                />
              </div>
              <div data-aos="fade-up" className="bm-ForFans__bannerDescription">
                <h1 className="text-bold text-white mb-4">Find things to do with event goers <span className="bm-Text--strokeText">near you</span></h1>
                <p className="lead text-white">
                  Making new friends and finding your tribe is hard. With Beatmatch, you can discover parties, concerts, and music festivals and connect with everyone going.
                </p>
                <SubscribeButton variant="primary">
                  <h5 className="pt-1">Get the app</h5>
                </SubscribeButton>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* {featuredEvents && featuredEvents.length && (
        <section className="bm-ForFans_section4 bm-ForFans_featuredEventsSection bm-Events__featuredEvents pb-3 text-white" style={{ overflowX: 'scroll' }}>
          <div className="bm-ForFans__featuredEvents--lg container d-none" style={{ height: '100%', width: '100%' }}>
            <h1 className="mb-5 text-white text-center">Featured on Beatmatch</h1>
            <div className="d-flex flex-row justify-content-between align-items-center flex-wrap mb-4">
              {featuredEvents.map(({
                id, name, provider, providerId,
                images = [], date, users, createdAt,
                venue,
              }: any) => {
                const momentDate = moment(date);
                const today = moment();
                const tomorrow = moment().add(1, 'day');
                const isToday = momentDate.isSame(today, 'day');
                const isTomorrow = momentDate.isSame(tomorrow, 'day');
                const userItems = get(users, 'items', []);
                const slug = slugify(name);

                return (
                  <div key={id} className="ml-2 mr-2 mb-3" style={{ maxWidth: '22.5%', overflow: 'hidden' }}>
                    <Link to={`/events/${providerToProviderCode[provider]}/${providerId}/${slug}`}>
                      <Image
                        className="mb-3"
                        src={get(images, '[0].url')}
                        style={{ aspectRatio: '1', width: '100%', borderRadius: 20, objectFit: 'cover' }}
                      />
                    </Link>
                    <div>
                      <div className="d-flex align-items-center">
                        {(isToday || isTomorrow) && (
                          <>
                            <h5 className="text-bold text-primary mr-2">
                              {isToday ? 'TODAY' : isTomorrow ? 'TOMORROW' : momentDate.format('ddd').toUpperCase()}
                            </h5>
                            <h5 className="text-bold">
                            {momentDate.format('MMM').toUpperCase()} {momentDate.format('D').toUpperCase()}
                            </h5>
                          </>
                        )}
                        {!isToday && !isTomorrow && (
                          <>
                            <h5 className="text-bold text-primary mr-2">
                              {momentDate.format('ddd').toUpperCase()}
                            </h5>
                            <h5 className="text-bold">
                              {momentDate.format('MMM').toUpperCase()} {momentDate.format('D').toUpperCase()}
                            </h5> 
                          </>
                        )}
                        {Boolean(userItems.length >= 10) && (
                          <div className="d-flex justify-content-center align-items-center ml-3" style={{ marginBottom: '0.4rem' }}>
                            <i className="fa fa-fire text-white mr-1" style={{ fontSize: 12 }} />
                            <h6 className="text-white text-bold" style={{ margin: 0, fontSize: 14 }}>
                              Trending
                            </h6>
                          </div>
                        )}
                      </div>
                      <h4 className="text-bold mb-2" style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{name}</h4>
                      {Boolean(venue) && <h4 className="bm-Events__featuredEvents__subtext" style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{(venue as any).name}</h4>}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="d-flex justify-content-center">
              <Button
                className="d-flex text-white text-center mb-3 align-items-center pl-5 pr-5"
                data-aos="fade-up"
                variant="secondary"
                href="/explore"
                style={{
                  borderRadius: 30,
                  fontWeight: 700,
                  backgroundColor: 'transparent',
                  border: '1.5px solid #fff',
                }}
              >
                <h5 className="pt-1">See all events</h5>
              </Button>
            </div>
          </div>
          <div className="bm-ForFans__featuredEvents--sm container d-none">
            <h1 className="text-white text-center mb-5 text-bold">Featured on Beatmatch</h1>
            <div className="bm-Event__linupArtistsContainer d-flex mb-4">
              {featuredEvents.filter(({ images = [] }) => Boolean(images.length)).map(({ name, images = [], date, venue, provider, providerId }: any, i: number) => {
                const momentDate = moment(date);
                const today = moment();
                const tomorrow = moment().add(1, 'day');
                const isToday = momentDate.isSame(today, 'day');
                const isTomorrow = momentDate.isSame(tomorrow, 'day');
                const slug = slugify(name);

                return (
                  <div
                    className={`d-flex flex-column ${
                      i < featuredEvents.length - 1 ? ' mr-4' : ''
                    }`}
                    style={{ width: '20%', minWidth: 200 }}
                  >
                    <Link
                      to={`/events/${providerToProviderCode[provider]}/${providerId}/${slug}`}
                    >
                      <Image
                        key={i}
                        src={get(images, '[0].url')}
                        className="mb-3"
                        style={{
                          height: undefined,
                          width: '100%',
                          aspectRatio: '1/1',
                          borderRadius: 20,
                          objectFit: 'cover',
                        }}
                      />
                    </Link>
                    <div style={{ width: '100%' }}>
                      <div className="d-flex mb-1">
                        {(isToday || isTomorrow) && (
                          <>
                            <h6 className="text-bold text-primary mr-2" style={{ marginBottom: 0 }}>
                              {isToday ? 'TODAY' : isTomorrow ? 'TOMORROW' : momentDate.format('ddd').toUpperCase()}
                            </h6>
                            <h6 className="text-bold text-white" style={{ marginBottom: 0 }}>
                            {momentDate.format('MMM').toUpperCase()} {momentDate.format('D').toUpperCase()}
                            </h6>
                          </>
                        )}
                        {!isToday && !isTomorrow && (
                          <>
                            <h6 className="text-bold text-primary mr-2" style={{ marginBottom: 0 }}>
                              {momentDate.format('ddd').toUpperCase()}
                            </h6>
                            <h6 className="text-bold text-white" style={{ marginBottom: 0 }}>
                              {momentDate.format('MMM').toUpperCase()} {momentDate.format('D').toUpperCase()}
                            </h6> 
                          </>
                        )}
                      </div>
                      <Link
                        to={`/events/${providerToProviderCode[provider]}/${providerId}/${slug}`}
                      >
                        <h5 className="text-bold text-white mb-1" style={{ maxWidth: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{name}</h5>
                      </Link>
                      {Boolean(venue) && <h5 className="bm-Artist__events__subtext" style={{ maxWidth: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{(venue as any).name}</h5>}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="d-flex justify-content-center">
              <Button
                className="d-flex text-white text-center mb-3 align-items-center pl-5 pr-5"
                data-aos="fade-up"
                variant="secondary"
                href="/events"
                style={{
                  borderRadius: 30,
                  fontWeight: 700,
                  backgroundColor: 'transparent',
                  border: '1.5px solid #fff',
                }}
              >
                <h5 className="pt-1">See all events</h5>
              </Button>
            </div>
          </div>
        </section>
      )} */}
      <section className="bg-dark container-fluid">
        {FEATURE_SECTIONS.map(({ heading, subheading, image, actionButton }, i) => (
          <div className="bm-ForFans__section" data-aos="fade-up">
            <FeatureSection
              heading={heading}
              subheading={subheading}
              image={image}
              reverse={i % 2 !== 0}
              actionButton={actionButton}
            />
            {Boolean(i === 1) && (
              <section className="pt-2 pb-2 bg-primary" style={{ width: '100%' }}>
                <Marquee
                  direction="right"
                  speed={25}
                  gradientColor={[153, 102, 255]}
                  gradientWidth={50}
                  style={{ overflowY: 'hidden', height: '100%' }}
                >
                  {[0,0,0,0,0,0,0,0,0,0].map((_, i) => (
                    <div data-aos="fade-up" className="d-flex flex-row align-items-center">
                      <h2 className={`${i % 2 === 0 ? '' : ''} text-bold font-italic text-white pl-5 pr-5`} style={{ marginBottom: 6 }}>
                        {`${i % 2 === 0 ? 'No mo\' FOMO' : 'More social, less media'}`}
                      </h2>
                      <Icon name={i % 2 === 0 ? Icons.ticket2 : Icons.ticket} className="bm-Icon--white ml-1 mr-1" size={26} />
                    </div>
                  ))}
                </Marquee>
              </section>
            )}
            {Boolean(i === 3) && (
              <section className="bm-ForFans_section6">
                <h5 className="text-white" data-aos="fade-up">Trusted by the best in entertainment</h5>
                <div className="d-flex flex-column align-items-center" data-aos="fade-up" style={{ width: '100%', overflowX: 'hidden' }}>
                  <div className="d-md-none" style={{ overflowX: 'hidden' }}>
                    <Marquee
                      direction="left"
                      speed={5}
                      pauseOnHover={true}
                      gradient={true}
                    >
                      <div className="bm-ForFans_investors container d-flex justify-content-center align-items-center">
                        <div className="bm-ForFans_investorLogo d-flex justify-content-center align-items-center mr-5">
                          <WarnerMusic fill="#fff" />
                        </div>
                        <div className="bm-ForFans_investorLogo d-flex justify-content-center align-items-center mr-5">
                          <QualityControlMusic fill="#fff" />
                        </div>
                        <div className="bm-ForFans_investorLogo d-flex justify-content-center align-items-center">
                          <SonyMusic fill="#fff" />
                        </div>
                        <div className="bm-ForFans_investorLogo d-flex justify-content-center align-items-center mr-5">
                          <HYBEMusic fill="#fff" />
                        </div>
                        <div className="bm-ForFans_investorLogo d-flex justify-content-center align-items-center mr-5">
                          <RightHandMusic fill="#fff" />
                        </div>
                        <div className="bm-ForFans_investorLogo d-flex justify-content-center align-items-center mr-5">
                          <ConcordMusic fill="#fff" />
                        </div>
                      </div>
                    </Marquee>
                  </div>
                  <div className="bm-ForFans_investors container d-md-flex justify-content-center align-items-center d-none">
                    <div className="bm-ForFans_investorLogo d-flex justify-content-center align-items-center mr-5">
                      <WarnerMusic fill="#fff" />
                    </div>
                    <div className="bm-ForFans_investorLogo d-flex justify-content-center align-items-center mr-5">
                      <QualityControlMusic fill="#fff" />
                    </div>
                    <div className="bm-ForFans_investorLogo d-flex justify-content-center align-items-center">
                      <SonyMusic fill="#fff" />
                    </div>
                    <div className="bm-ForFans_investorLogo d-flex justify-content-center align-items-center mr-5">
                      <HYBEMusic fill="#fff" />
                    </div>
                    <div className="bm-ForFans_investorLogo d-flex justify-content-center align-items-center mr-5">
                      <RightHandMusic fill="#fff" />
                    </div>
                    <div className="bm-ForFans_investorLogo d-flex justify-content-center align-items-center mr-5">
                      <ConcordMusic fill="#fff" />
                    </div>
                  </div>
                </div>
              </section>
            )}
          </div>
        ))}
      </section>
      <section className="bg-dark">
        <div className="container bm-center_children" style={{ height: '100%', width: '100%' }}>
          <div style={{ width: '100%' }}>
            <div
              data-aos="fade-up"
              className={`d-flex justify-content-between align-items-center flex-wrap container flex-row-reverse`}
              style={{
                display: 'block',
                paddingTop: '3rem',
                paddingBottom: '3rem',
                width: '100%',
              }}
            >
              <div data-aos="fade-up" className="bm-ForFans__bannerImage bm-ForFans__bannerImage--large bm-ForFans__bannerImage--reverse">
                {/* <iframe
                  width="100%"
                  height={400}
                  src="https://www.youtube.com/embed/b3hkPlKhYec?controls=0"
                  title="YouTube video player"
                  frameBorder={0}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="rounded"
                  style={{ aspectRatio: '3/4'}}
                /> */}
                <Image
                  src={EventsParty}
                  width="100%"
                  height={400}
                  style={{ aspectRatio: '3/4', objectFit: 'cover', borderRadius: 20 }}
                />
              </div>
              <div data-aos="fade-up" className="bm-ForFans__bannerDescription">
                <h1 className="text-bold text-white">On a mission to end loneliness</h1>
                <>
                  <p className="text-white lead" style={{ width: '100%' }}>
                    79% of Gen-Z is lonely. Don't be a statistic. Be more social on Beatmatch.
                  </p>
                </>
                <SubscribeButton variant="primary">
                  <h5 className="pt-1">Be more social</h5>
                </SubscribeButton>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bm-ForFans_section7 container-fluid">
        <div className="bg-primary container p-5 text-left d-flex justify-content-between flex-wrap" data-aos="fade-up" style={{ position: 'relative', borderRadius: 20, overflow: 'hidden' }}>
          <div>
            <h1 className="text-white">Ready to turn up?</h1>
            <h1 className="bm-Text--strokeText mb-5">Join everyone on Beatmatch</h1>
            {/* <Button
              className="d-flex text-black mr-3 align-items-center mb-5"
              variant="secondary"
              href="https://apps.apple.com/us/app/beatmatch-music-dating-app/id1540216913"
              target="_blank"
              style={{ borderRadius: 30, fontWeight: 700, height: 45, minWidth: '50%' }}
            >
              <h5 className="pt-1 text-dark mr-2">Get started</h5>
            </Button> */}
            <SubscribeButton className="text-dark" variant="secondary">
              <h5 className="pt-1">Get started</h5>
            </SubscribeButton>
          </div>
          <div className="d-none d-md-block" style={{ position: 'absolute', bottom: 0, right: '2rem' }}>
            <Image
              src={JoinTheParty}
              width={500}
            />
          </div>
          <div className="d-flex d-md-none justify-content-center" style={{ marginBottom: -50, width: '100%' }}>
            <Image
              src={JoinTheParty}
              width={500}
            />
          </div>
        </div>
      </section>
      <ScrollToTop />
    </>
  );
};

export default ForFans;