/* External dependencies */
import { API } from 'aws-amplify';
import { cleanObject } from 'src/common/cleanObject';
import { PaginationOptions } from 'src/types/Pagination';

/* Internal dependencies */
import { ApiName } from '../aws-exports';
import { cacheify } from 'src/helpers/cacheify';

type LocationOptions = {
  longitude?: number;
  latitude?: number;
  range?: 'global' | 'local';
};

export const listLocations = async (limit: number = 30) =>
  await API.get(ApiName.bmApp, `/locations`, {
    queryStringParameters: { limit },
  });

export const getLocation = async (locationId: string) =>
  await API.get(ApiName.bmApp, `/locations/${locationId}`, {});

export const listEventsForLocation = async (locationId: string, { limit, offset }: PaginationOptions) =>
  await API.get(ApiName.bmApp, `/locations/${locationId}/events2`, {
    queryStringParameters: cleanObject({
      limit,
      offset: Boolean(offset) && JSON.stringify(offset),
    }),
  });

export const locationView = async (locationId: string, params: PaginationOptions & { type: 'event' | 'neighborhood' | 'venue'; }) => 
  await API.get(ApiName.bmApp, `/locations/${locationId}/locationView`, {
    queryStringParameters: cleanObject(params),
  });

const cachedSearchLocations = cacheify<(...args: any[]) => Promise<{ items: any[] }>, { items: any[] }>(
  async (q: string, { limit, offset, latitude, longitude, range = 'local' }: PaginationOptions & LocationOptions) =>
    await API.get(ApiName.bmApp, '/search', {
      queryStringParameters: {
        q,
        type: 'location',
        provider: 'google',
        range,
        limit,
        offset,
        latitude,
        longitude,
      },
    }),
  60000
);
  
export const searchLocations = async (q: string, options: PaginationOptions & LocationOptions) => await cachedSearchLocations()(q, options);