/* Internal dependencies */
import Event from './Event';
import Order from './Order';
import { PaginationOptions } from './Pagination';

export enum ImageProvider {
  facebook = 'facebook',
  instagram = 'instagram',
  local = 'local',
}

export type Image = {
  key?: string;
  provider: ImageProvider;
  url: string;
  height: number;
  width: number;
};

export enum Gender {
  male = 'male',
  female = 'female',
  other = 'other',
  all = 'all',
  everyone = 'everyone',
}

export type Institution = {
  id: string;
  name: string;
  graduationYear?: number;
};

export type Job = {
  id: string;
  title: string;
  company: string;
};

export type User = {
  id: string;
  name: string;
  firstName?: string;
  lastName?: string;
  type: 'user';
  email?: string;
  phone?: string;
  gender?: Gender;
  genderPreference?: Gender;
  birthday?: Date;
  bio?: string;
  instagramUsername?: string;
  tikTokUsername?: string;
  images: Image[];
  activities: any[];
  activitiesTotal: number;
  activitiesOffset: PaginationOptions['offset'];
  achievements: any[];
  achievementsTotal: number;
  achievementsOffset: PaginationOptions['offset'];
  achievementGroups: any[];
  achievementGroupsTotal: number;
  achievementGroupsOffset: PaginationOptions['offset'];
  communities: any[];
  communitiesTotal: number;
  communitiesOffset: PaginationOptions['offset'];
  events: Event[];
  eventsTotal: number;
  eventsOffset: PaginationOptions['offset'];
  orders: Order[];
  ordersTotal: number;
  ordersOffset: PaginationOptions['offset'];
  sharedEvents: any[];
  sharedEventsTotal: number;
  sharedEventsOffset: PaginationOptions['offset'];
  artists: any[];
  artistsTotal: Object;
  artistsOffset: PaginationOptions['offset'];
  sharedArtists: any[];
  sharedArtistsTotal: number;
  sharedArtistsOffset: PaginationOptions['offset'];
  tracks: any[];
  genres: any[];
  desires: any[];
  travels: any[];
  stories: any[];
  friends: any[];
  friendsOffset: PaginationOptions['offset'];
  friendsTotal: number;
  friendRequests: any[];
  friendRequestsOffset: PaginationOptions['offset'];
  friendRequestsTotal: number;
  contacts: any[];
  contactsTotal: number;
  friendStatus?: string;
  genreIds: Set<string>;
  desireIds: Set<string>;
  artistIds: Set<string>;
  eventIds: Set<string>;
  notifications: any[];
  notificationsOffset: Object;
  notificationsUnseenTotal: number;
  testUser?: boolean;
  defaultCollectionId?: string;
  verified: Object;
  deviceTokens: {
    ios?: string;
    android?: string;
  };
  answers: any[];
  education: {
    activeInstitution: number;
    institutions: Institution[];
  };
  location: {
    latitude: number;
    longitude: number;
    city: string;
    state: string;
  };
  credentials: {
    [provider: string]: {
      provider: string;
      providerId: string;
    };
  };
  preferences: {
    [preference: string]: any;
  };
  savedTracks: any[];
  score: number;
  referralId?: string;
  createdAt: Date;
  updatedAt: Date;
};

export default User;