/* External dependencies */
import { createStore, compose, combineReducers, Store, Action, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import Storage from 'redux-persist/lib/storage';
import { connectRouter, routerMiddleware, RouterState } from 'connected-react-router';
import { createBrowserHistory } from 'history';

/* Internal dependencies */
import currentUserReducer, { CurrentUserState, INITIAL_STATE as CURRENT_USER_INITIAL_STATE } from './ducks/currentUser';
import playerReducer, { PlayerState, INITIAL_STATE as PLAYER_INITIAL_STATE } from './ducks/player';
import eventsReducer, { EventsState, INITIAL_STATE as EVENTS_INITIAL_STATE } from './ducks/events';
import postsReducer, { PostsState, INITIAL_STATE as POSTS_INITIAL_STATE } from './ducks/posts';
import likesReducer, { LikesState, INITIAL_STATE as LIKES_INITIAL_STATE } from './ducks/likes';
import notificationsReducer, {
  NotificationsState,
  INITIAL_STATE as NOTIFICATIONS_INITIAL_STATE,
} from './ducks/notifications';
import artistsReducer, { ArtistsState, INITIAL_STATE as ARTISTS_INITIAL_STATE } from './ducks/artists';
import activitiesReducer, {
  ActivitiesState,
  INITIAL_STATE as ACTIVITIES_INITIAL_STATE,
  friendsActivitiesReducer,
  FriendsActivitiesState,
  FRIENDS_INITIAL_STATE as FRIENDS_ACTIVITIES_INITIAL_STATE,
} from './ducks/activities';
import storiesReducer, {
  StoriesState,
  INITIAL_STATE as STORIES_INITIAL_STATE,
  friendsStoriesReducer,
  FriendsStoriesState,
  FRIENDS_INITIAL_STATE as FRIENDS_STORIES_INITIAL_STATE,
} from './ducks/stories';
import communityFeedItemsReducer, { PAGINATOR_ID as COMMUNITY_FEED_PAGINATOR_ID } from './ducks/communityFeedItems';
import { ItemsState, INITIAL_STATE as PAGINATOR_INITIAL_STATE } from './ducks/paginator';
import commentsReducer, { CommentsState, INITIAL_STATE as COMMENTS_INITIAL_STATE } from './ducks/comments';
import usersReducer, { UsersState, INITIAL_STATE as USERS_INITIAL_STATE } from './ducks/users';
import communitiesReducer, { CommunitiesState, INITIAL_STATE as COMMUNITIES_INITIAL_STATE } from './ducks/communities';
import browseReducer, { BrowseState, INITIAL_STATE as BROWSE_INITIAL_STATE } from './ducks/browse';
import routesReducer, { RoutesState, INITIAL_STATE as ROUTES_INITIAL_STATE } from './ducks/routes';
import transforms from './transforms';

enum RootActionType {
  LOGOUT = 'reducer/root/LOGOUT',
}

export type ApplicationState = {
  currentUser: CurrentUserState;
  events: EventsState;
  artists: ArtistsState;
  communities: CommunitiesState;
  users: UsersState;
  stories: StoriesState;
  friendsStories: FriendsStoriesState;
  activities: ActivitiesState;
  friendsActivities: FriendsActivitiesState;
  posts: PostsState;
  comments: CommentsState;
  likes: LikesState;
  browse: BrowseState;
  routes: RoutesState;
  [COMMUNITY_FEED_PAGINATOR_ID]: ItemsState;
  notifications: NotificationsState;
  player: PlayerState;
  router: RouterState;
};

// Create the history object for use with connected-react-router
export const history = createBrowserHistory();

// Construct the router's initial state
const INITIAL_ROUTER_STATE: RouterState = {
  location: history.location as any,
  action: history.action,
};

/** Combined set of all reducers. */
export const reducers = combineReducers<ApplicationState>({
  currentUser: currentUserReducer,
  events: eventsReducer,
  artists: artistsReducer,
  communities: communitiesReducer,
  users: usersReducer,
  stories: storiesReducer,
  friendsStories: friendsStoriesReducer,
  activities: activitiesReducer,
  friendsActivities: friendsActivitiesReducer,
  posts: postsReducer,
  comments: commentsReducer,
  likes: likesReducer,
  browse: browseReducer,
  routes: routesReducer,
  [COMMUNITY_FEED_PAGINATOR_ID]: communityFeedItemsReducer,
  notifications: notificationsReducer,
  player: playerReducer,
  router: connectRouter(history),
});

const INITIAL_STATE: ApplicationState = {
  currentUser: CURRENT_USER_INITIAL_STATE,
  player: PLAYER_INITIAL_STATE,
  artists: ARTISTS_INITIAL_STATE,
  events: EVENTS_INITIAL_STATE,
  likes: LIKES_INITIAL_STATE,
  browse: BROWSE_INITIAL_STATE,
  routes: ROUTES_INITIAL_STATE,
  notifications: NOTIFICATIONS_INITIAL_STATE,
  activities: ACTIVITIES_INITIAL_STATE,
  friendsActivities: FRIENDS_ACTIVITIES_INITIAL_STATE,
  stories: STORIES_INITIAL_STATE,
  friendsStories: FRIENDS_STORIES_INITIAL_STATE,
  posts: POSTS_INITIAL_STATE,
  comments: COMMENTS_INITIAL_STATE,
  users: USERS_INITIAL_STATE,
  communities: COMMUNITIES_INITIAL_STATE,
  [COMMUNITY_FEED_PAGINATOR_ID]: PAGINATOR_INITIAL_STATE,
  router: INITIAL_ROUTER_STATE,
};

const rootReducer: any = (state: ApplicationState, action: Action) => {
  if (action.type === RootActionType.LOGOUT) {
    return reducers(INITIAL_STATE, action);
  }

  return reducers(state, action);
};

const persistConfig: any = {
  key: 'root',
  storage: Storage,
  whitelist: [
    'forYouFeedItems',
    // 'friendsFeedItems',
    // 'primaryChats',
    // 'activityChats',
    // 'chatRequestChats',
    // 'chats',
    // 'posts',
    // 'polls',
    // 'pollOptions',
    'events',
    'communities',
    // 'browse',
    'currentUser',
    // 'notifications',
    // 'permissions',
    'player',
  ],
  transforms,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Combine all middlewares into one array
const middleware = [routerMiddleware(history)];

// Set up the Redux DevTools Extension if available
const composeEnhancers =
  ((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })) || compose;

// Combine all middleware with the DevTools integration
const composedEnhancers = composeEnhancers(applyMiddleware(...middleware));

// Create the Redux store with the composed enhancers
const store: Store<ApplicationState> = createStore(
  persistedReducer,
  composedEnhancers // Pass the composed enhancers here
);

export const persistor = persistStore(store);

export default store;
