/* External dependencies */
import { API } from 'aws-amplify';
import { cleanObject } from 'src/common/cleanObject';

/* Internal dependencies */
import { ApiName } from '../aws-exports';
import { cacheify } from '../helpers/cacheify';
import { PaginationOptions } from '../types/Pagination';
import { Image } from 'src/types/User';

export const addArtistForUser = async (userId: string, artist: any) =>
  await API.post(ApiName.bmApp, `/users/${userId}/artists`, {
    body: artist,
  });

export const removeArtistForUser = async (userId: string, artistId: string) =>
  await API.del(ApiName.bmApp, `/users/${userId}/artists/${artistId}`, {});

export const addArtist = async (artist: any) =>
  await API.post(ApiName.bmApp, `/artists`, {
    body: artist,
  });

export const addImageForArtist = async (artistId: string, image: Omit<Image, 'url'>) =>
  await API.post(ApiName.bmApp, `/artists/${artistId}/images`, {
    body: image,
  });

export const listArtistsForUser = async (userId: string, { limit, offset }: PaginationOptions) =>
  await API.get(ApiName.bmApp, `/users/${userId}/artists`, {
    queryStringParameters: {
      limit,
      offset: JSON.stringify(offset),
    },
  });

export const getArtists = async (artistIds: string[]) =>
  await API.get(ApiName.bmApp, `/artists/getArtists`, {
    queryStringParameters: {
      artistIds: artistIds.join(','),
    },
  });

export const getArtist = async (artistId: string, options?: { cache: boolean }) =>
  await API.get(ApiName.bmApp, `/artists/${artistId}`, {
    queryStringParameters: cleanObject({
      cache: options && options.cache,
    }),
  });

const cachedSearchArtists = cacheify<(...args: any[]) => Promise<{ items: any[] }>, { items: any[] }>(
  async (q: string, { limit, offset }: PaginationOptions) =>
    await API.get(ApiName.bmApp, '/search', {
      queryStringParameters: {
        q,
        type: 'artist',
        limit,
        offset,
      },
    }),
  60000
);

export const searchArtists = async (q: string, options: PaginationOptions) => await cachedSearchArtists()(q, options);
