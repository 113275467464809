/* External dependencies */
import { API } from 'aws-amplify';
import { cleanObject } from 'src/common/cleanObject';
import { PaginationOptions } from 'src/types/Pagination';

/* Internal dependencies */
import { ApiName } from '../aws-exports';
import { User, Image } from '../types/User';

export const getUser = async (userId: string): Promise<User> => await API.get(ApiName.bmApp, `/users/${userId}`, {});

export const addImageForUser2 = async (userId: string, image: Omit<Image, 'url'>) =>
  await API.post(ApiName.bmApp, `/users/${userId}/images2`, {
    body: image,
  });

export const updateNameForUser = async (userId: string, name: string) =>
  await API.put(ApiName.bmApp, `/users/${userId}/name`, {
    body: { name },
  });

export const updateEmailForUser = async (userId: string, email: string) =>
  await API.put(ApiName.bmApp, `/users/${userId}/email`, {
    body: { email },
  });

export const deleteUser = async (userId: string) => await API.del(ApiName.bmApp, `/users/${userId}`, {});

export const listUsers = async ({ limit = 15, offset }: PaginationOptions) =>
  await API.get(ApiName.bmApp, `/users`, {
    queryStringParameters: cleanObject({
      limit,
      offset: (Boolean(offset) && JSON.stringify(offset)) || undefined,
    }),
  });

export const searchUsers = async (q: string, { limit = 15, offset }: PaginationOptions) =>
  await API.get(ApiName.bmApp, `/users`, {
    queryStringParameters: cleanObject({
      q,
      limit,
      offset: (Boolean(offset) && JSON.stringify(offset)) || undefined,
    }),
  });
