/* External dependencies */
import React from 'react';
import get from 'lodash/get';

/* Internal dependencies */
import MediaObject, { Props as MediaObjectProps } from '../mediaObject/MediaObject';
import { User as UserType } from 'src/types/User';
import { formatNumber } from 'src/common/helpers/numbers';
import UserDefaultAvatar from './UserDefaultAvatar';

type OwnProps = {
  user: UserType;
  right?: React.ReactNode;
} & Partial<MediaObjectProps>;

type Props = OwnProps;

const User: React.FunctionComponent<Props> = ({ user, right, ...rest }) => {
  const createdEvents = get(user, 'createdEvents', []);
  const createdEventsTotal = get(user, 'createdEventsTotal', createdEvents.length);
  const pastEvents = get(user, 'pastEvents', []);
  const pastEventsTotal = get(user, 'pastEventsTotal', pastEvents.length);
  const userImageUrl = get(user, 'images[0].url');

  return (
    <MediaObject
      text={get(user, 'name')}
      subtext={Boolean(createdEventsTotal !== undefined || pastEventsTotal !== undefined) ? `${Boolean(createdEventsTotal) ? `${formatNumber(createdEventsTotal)} hosted, ` : ''}${formatNumber(pastEventsTotal)} attended` : undefined}
      imageType="circle"
      image={Boolean(userImageUrl) ? userImageUrl : (
        <UserDefaultAvatar
          user={user}
          size={get(rest, 'imageSize')}
        />
      )}
      right={right}
      {...rest}
    />
  );
};

export default User;
